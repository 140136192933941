const reducer = (state = 0, action) => {
  switch (action.type) {
    case "BANK_NAME_LIST":
      return { "bankNameList": action.payload };

    case "ADD_NEW_BANK_NAME":
      return { "addNewBankName": action.payload };

    case "GET_BANK_NAME_DETAIL":
      return { "getBankNameDetail": action.payload };

    case "UPDATE_BANK_NAME_DETAIL":
      return { "updateBankNameDetail": action.payload };

    case "CHANGE_BANK_NAME_STATUS":
      return { "changeBankNameStatus": action.payload };

    case "BANNER_LIST":
      return { "bannerList": action.payload };

    case "UPDATE_AD_BANNER":
      return { "updateAdBanner": action.payload };

    case "ADD_NEW_BANNER":
      return { "addNewBanner": action.payload };

    case "CHANGE_AD_BANNER_STATUS":
      return { "adBannerStatus": action.payload };

    case "VIEW_AD_BANNER":
      return { "viewAdBanner": action.payload };

    case "DELETE_AD_BANNER":
      return { "deleteBanner": action.payload };

    case "QUOTE_LIST":
      return { "getQuoteList": action.payload };

    case "DELETE_QUOTE":
      return { "deleteQuote": action.payload };

    case "ADD_QUOTE":
      return { "addQuote": action.payload };

    case "CONTACT_US_LIST":
      return { "getContactUsList": action.payload };

    case "CHANGE_CONTACT_US_STATUS":
      return { "changeConatctUsStatus": action.payload };

    case "DELETE_CONTACT_US":
      return { "deleteConatctUs": action.payload };

    case "ADD_CONTACT_US":
      return { "addContactUsPersonal": action.payload };

    case "VIEW_ADD_CONTACT_US":
      return { "viewContactUs": action.payload };

    case "UPDATE_CONTACT_US_PERSONAL":
      return { "updateContactUsPersonal": action.payload };

    case "REFERRAL_LIST":
      return { "referralList": action.payload };

    case "RESPONSE_ERROR":
      return { "responseError": action.payload };

    case "API_ERROR":
      return { "apiError": action.payload };

    default:
      return state;

    // Switch ends here================================
  }
}

export default reducer;
