

const reducer = (state = 0, action) => {
  switch (action.type) {
    case "LENDER_LIST":
      return { "lenderList": action.payload };

    case "CHANGE_STATUS":
      return { "changeStatus": action.payload };

    case "ADD_LENDER":
      return { "addLender": action.payload };

    case "GET_LENDER_PROFILE_DETAILS":
      return { "getLenderProfileDetails": action.payload };

    case "DELETE_LENDER":
      return { "deleteLender": action.payload };

    case "RESPONSE_ERROR":
      return { "responseError": action.payload };

    case "API_ERROR":
      return { "apiError": action.payload };

    default:
      return state

    // Switch ends here===============================
  }

}

export default reducer;

