

const reducer = (state = 0, action) => {
    switch (action.type) {
        case "deposit":
            return {"deposit":action.payload};

        case "withdraw":
            return {"withdraw":action.payload};

        case "broList":
            return {"broList":action.payload};

        case "BORROWER_LIST":
            return { "borrowerList":action.payload };

        case "CHANGE_STATUS":
            return { "changeStatus":action.payload };

        case "BORROWER_SIGNUP":
            return { "borrowerSignup":action.payload };

        case "BORROWER_PROFILE":
            return { "borrowerProfile":action.payload };

        case "UPDATE_BORROWER":
            return { "updateBorrower":action.payload };

        case "DELETE_BORROWER":
            return { "deleteBorrower":action.payload };

        case "RESPONSE_ERROR":
            return { "responseError":action.payload };

        case "API_ERROR":
            return { "apiError":action.payload };

        default:
            return state

        // Switch ends here===============================
    }

}

export default reducer;

