const reducer = (state = 0, action) => {
  switch (action.type) {
    case "SUBSCRIPTION_PACKAGE_LIST":
      return { "subscriptionPackageList": action.payload };

    case "CHANGE_SUBSCRIPTION_PACKAGE_STATUS":
      return { "changeSubscriptionStatus": action.payload };

    case "DELETE_SUBSCRIPTION_PACKAGE":
      return { "deleteSubscriptionPackage": action.payload };

    case "ADD_SUBSCRIPTION_PACKAGE":
      return { "addSubscriptionPackage": action.payload };

    case "UPDATE_SUBSCRIPTION_PACKAGE":
      return { "updateSubscriptionPackage": action.payload };

    case "VIEW_SUBSCRIPTION_PACKAGE":
      return { "viewSubscriptionpackageDeatils": action.payload };

    case "GET_SUBSCRIPTION_HISTORY_LIST":
      return { "getSubscriptionHistoryList": action.payload };

    case "VIEW_SUBSCRIPTION_HISTORY_DETAILS":
      return { "viewSubscriptionHistoryDetails": action.payload };

    case "RESPONSE_ERROR":
      return { "responseError": action.payload };

    case "API_ERROR":
      return { "apiError": action.payload };

    default:
      return state

    // Switch ends here===============================
  }

}

export default reducer;
