

const reducer = (state = 0, action) => {
  switch (action.type) {
    case "APPLY_LOAN_LIST":
      return {
        "applyLoanList": action.payload
      };

    case "GET_APPLY_LOAN_DETAILS":
      return {
        "getApplyLoanDetails": action.payload
      };

    case "GET_ASSIGN_LOAN_LIST":
      return {
        "getAssignLoanList": action.payload
      };

    case "GET_ASSIGN_LOAN_DETAILS":
      return {
        "getAssignLoanDetails": action.payload
      };

    case "RESPONSE_ERROR":
      return {
        "responseError": action.payload
      };

    case "API_ERROR":
      return {
        "apiError": action.payload
      };

    default:
      return state

    // Switch ends here===============================
  }

}

export default reducer;
