import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import Loader from "react-loader-spinner";

const loading = (
  <div className="pt-3 text-center style-loader" style={{ marginTop: "18%" }}>
    <Loader
      type="Puff"
      color="#00BFFF"
      height={100}
      width={100}
      timeout={3000} //3 secs
    />
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./layouts/adminLayout/TheLayout'));

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'));
const AdminLogin = React.lazy(() => import('./components/auth/admin/AdminLogin'));

const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Home = React.lazy(() => import('./components/Home'));

class App extends Component {

  render() {
    return (
      <BrowserRouter >
        <React.Suspense fallback={loading}>

          <Switch>
            <Route exact path="/" name="Home" render={props => <Home {...props} />} />
            <Route exact path="/admin-login" name="Login Page" render={props => <AdminLogin {...props} />} />
            <Route exact path="/password-reset/:email/:token" name="Login Page" render={props => <AdminLogin {...props} />} />

            <Route exact path="/admin-dashboard" name="Dashboard" render={props => <TheLayout {...props} />} />
            {/* Admin Borrower Section */}
            <Route exact path="/admin-borrower-list" name="Borrower List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-add-borrower" name="Add Borrower" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-view-borrower" name="View Borrower" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-update-borrower" name="Update Borrower" render={props => <TheLayout {...props} />} />
            {/* Admin Lender Section */}
            <Route exact path="/admin-lender-list" name="Lender List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-add-lender" name="Add Lender" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-view-lender" name="View Lender" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-Update-lender" name="Update Lender" render={props => <TheLayout {...props} />} />
            {/* Admin Subscription Section */}
            <Route exact path="/admin-subscription-package-list" name="Subscription Package List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-add-subscription-package" name="Add Subscription Package" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-view-subscription-package" name="View Subscription Package" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-update-subscription-package" name="Update Subscription Package" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-subscription-history-list" name="Subscription History List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-view-subscription-details" name="View Subscription Details" render={props => <TheLayout {...props} />} />
            {/* Admin Notification Section */}
            <Route exact path="/admin-notification-list" name="Notification List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-compose-notification" name="Compose Notification" render={props => <TheLayout {...props} />} />
            {/* Admin CMS Section */}
            <Route exact path="/admin-pages" name="Page List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-add-page" name="Add Page" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-update-page" name="Update Page" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-view-page" name="View Page" render={props => <TheLayout {...props} />} />
            {/* Admin Loan Management section */}
            <Route exact path="/admin-apply-loan-list" name="Apply Loan List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-view-apply-loan-details" name="View Apply Loan Details" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-assign-loan-list" name="Assign Loan List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-view-assign-loan-details" name="Assign Loan Details" render={props => <TheLayout {...props} />} />
            {/* Admin Referral Section */}
            <Route exact path="/admin-referral-list" name="Referral List" render={props => <TheLayout {...props} />} />
            {/* Admin Setting Section */}
            <Route exact path="/admin-admin-setting" name="Admin Settings" render={props => <TheLayout {...props} />} />
            {/* More Option Section */}
            <Route exact path="/admin-bank-name-list" name="Bank Name List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-add-bank-name" name="Add Bank Name" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-update-bank-name" name="UpdateBank Name" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-banner-list" name="Banner List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-add-banner" name="Add Banner" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-view-banner" name="View Banner" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-update-banner" name="Update Banner" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-quote-list" name="Quote List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-get-contact-us-list" name="Contact Us List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-add-contact-us" name="Add Contact Us" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-update-contact-us" name="Update Contact Us" render={props => <TheLayout {...props} />} />

            {/* Help & Support Section */}
            <Route exact path="/admin-web-enquiry-list" name="Web Enquiry List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-app-enquiry-list" name="App Enquiry List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-faq-list" name="Faq List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-add-faq" name="Add Faq" render={props => <TheLayout {...props} />} />

            {/* WEB Manager Section */}
            <Route exact path="/admin-loan-content-list" name="Loan/ About us Content List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-add-loan-content" name="Add Loan/ About us Content" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-update-loan-content" name="Update Loan/ About us Content" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-view-loan-content" name="View Loan/ About us Content" render={props => <TheLayout {...props} />} />

            <Route exact path="/admin-borrower-lender-content-list" name="Borrower Lender Content List" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-add-borrower-lender-content" name="Add Borrower/ Lender Content" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-update-borrower-lender-content" name="Update Borrower/ Lender Content" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-view-borrower-lender-content" name="View Borrower/ Lender Content" render={props => <TheLayout {...props} />} />

            <Route exact path="/admin-our-community-list" name="Our Communities" render={props => <TheLayout {...props} />} />

            <Route exact path="/admin-add-community" name="Add New Community" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-update-community" name="Update Our Community" render={props => <TheLayout {...props} />} />


            <Route exact path="/admin-help-content-list" name="Help Content" render={props => <TheLayout {...props} />} />
            <Route exact path="/admin-update-content-list" name="Update Help Content" render={props => <TheLayout {...props} />} />





            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />

          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
