
const reducer = (state = 0, action) => {
  switch (action.type) {
    case "GET_NOTIFICATION_LIST":
      return { "getNoificationList": action.payload };

    case "ADD_NOTIFICATION":
      return { "addNotification": action.payload };

    case "DELETE_NOTIFICATION":
      return { "deleteNotification": action.payload };

    case "SEND_NOTIFICATION":
      return { "sendNotification": action.payload };

    case "RESPONSE_ERROR":
      return { "responseError": action.payload };

    case "API_ERROR":
      return { "apiError": action.payload };

    default:
      return state;

    // Switch ends here================================
  }
}

export default reducer;
