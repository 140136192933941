
const reducer = (state = 0, action) => {
    switch (action.type) {
        case "CMS_LIST":
            return { "cmsList": action.payload };

        case "CHANGE_CMS_STATUS":
            return { "changeCmsStatus": action.payload };
            
        case "ADD_CMS_PAGE":
            return { "addCmsPage": action.payload };

        case "VIEW_CMS_PAGE":
            return { "viewCmsPage": action.payload };

        case "UPDATE_CMS_PAGE":
            return { "updateCmsPage": action.payload };

        case "DELETE_CMS_PAGE":
            return { "deleteCmsPage": action.payload };

        case "RESPONSE_ERROR":
            return { "responseError":action.payload };     

        case "API_ERROR":
            return { "apiError":action.payload };

        default:
            return state;    

        // Switch ends here================================
    }
}

export default reducer;