

const reducer = (state = 0, action) => {
  switch (action.type) {

    case "LOAN_CONTENT_LIST":
      return { "getLoanContentList": action.payload };

    case "ADD_LOAN_CONTENTS":
      return { "addLoanContents": action.payload };

    case "VIEW_LOAN_CONTENT":
      return { "viewLoanAboutUsContent": action.payload };

    case "UPDATE_LOAN_CONTENT":
      return { "updateLoanAboutUsContents": action.payload };

    case "CHANGE_LOAN_CONTENT_STATUS":
      return { "changeLoanContentStatus": action.payload };

    case "DELETE_LOAN_CONTENT":
      return { "deleteLoanContent": action.payload };

    case "BORROWER_LENDER_CONTENT_LIST":
      return { "getBorrowerLenderContentList": action.payload };

    case "ADD_BORROWER_LENDER_CONTENTS":
      return { "addBorrowerLenderContents": action.payload };

    case "VIEW_BORROWER_LENDER_CONTENT":
      return { "viewBorrowerLenderContent": action.payload };

    case "UPDATE_BORROWER_LENDER_CONTENT":
      return { "updateBorrowerLenderContents": action.payload };

    case "CHANGE_BORROWER_LENDER_CONTENT_STATUS":
      return { "changeBorrowerLenderContentStatus": action.payload };

    case "DELETE_BORROWER_LENDER_CONTENT":
      return { "deleteBorrowerLenderContent": action.payload };

    case "GET_COMMUNITY_LIST":
      return { "getCommunityList": action.payload };

    case "VIEW_COMMUNITY_PERSON":
      return { "viewCommunityPerson": action.payload };

    case "UPDATE_COMMUNITY_PERSON":
      return { "updateCommunityPerson": action.payload };

    case "CHANGE_COMMUNITY_PERSON_STATUS":
      return { "changeCommunityPersonStatus": action.payload };

    case "DELETE_COMMUNITY_PERSON":
      return { "deleteCommunityPerson": action.payload };

    case "ADD_COMMUNITY":
      return { "addCommunity": action.payload };

    case "GET_HELP_CONTENT_LIST":
      return { "getHelpContentList": action.payload };

    case "CHANGE_HELP_CONTENT_STATUS":
      return { "changeHelpContentStatus": action.payload };

    case "UPDATE_HELP_CONTENT":
      return { "updateHelpContent": action.payload };

    case "VIEW_HELP_CONTENT":
      return { "viewHelpContent": action.payload };

    case "API_ERROR":
      return { "apiError": action.payload };

    default:
      return state

    // Switch ends here===============================
  }

}

export default reducer;
