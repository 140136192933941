
const reducer = (state = 0, action) => {
    switch (action.type) {
        case "WEB_ENQUIRY_LIST":
            return { "webEnquiryList": action.payload };

        case "APP_ENQUIRY_LIST":
            return { "appEnquiryList": action.payload };
            
        case "FAQ_LIST":
            return { "faqList": action.payload };

        case "ADD_FAQ":
            return { "addFaq": action.payload };

        case "DELETE_FAQ":
            return { "deleteFaq": action.payload };

        case "RESPONSE_ERROR":
            return { "responseError":action.payload };  

        case "API_ERROR":
            return { "apiError":action.payload };

        default:
            return state;    

        // Switch ends here================================
    }
}

export default reducer;