

const reducer = (state = 0, action) => {
  switch (action.type) {

    case "ADMIN_PROFILE":
      return { "adminProfile": action.payload };

    case "RESPONSE_ERROR":
      return { "responseError": action.payload };

    case "API_ERROR":
      return { "apiError": action.payload };

    default:
      return state

    // Switch ends here===============================
  }

}

export default reducer;
