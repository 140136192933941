
const reducer = (state = 0, action) => {
  switch (action.type) {
    case "SUBMIT_ENQUIRY":
      return { "submitEnquiry": action.payload };

    case "SUBSCRIBE_NEWS_LETTER":
      return { "subscribeNewsLetter": action.payload };

    case "GET_LOAN_CONTENT":
      return { "getLoanContent": action.payload };

    case "GET_BORROWER_LENDER_CONTENT":
      return { "getBorrowerLenderContent": action.payload };

    case "GET_OUR_COMMUNITY":
      return { "getOurCommunity": action.payload };

    case "GET_HELP_CONTENT":
      return { "getHelpContent": action.payload };

    case "RESPONSE_ERROR":
      return { "responseError": action.payload };

    case "API_ERROR":
      return { "apiError": action.payload };

    default:
      return state;

    // Switch ends here================================
  }
}

export default reducer;
