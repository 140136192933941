import { combineReducers } from 'redux';
import adminReducer from './adminReducer';
import borrowerReducer from './borrowerReducer';
import cmsReducer from './cmsReducer';
import lenderReducer from './lenderReducer';
import subscriptionReducer from './subscriptionReducer';
import moreOptionReducer from './moreOptionReducer';
import homePageReducer from './homePageReducer';
import helpAndSupportReducer from './helpAndSupportReducer';
import loanReducer from './loanReducer';
import webManagerReducer from './webmanagerReducer';
import notificationReducer from './notificationReducer';

const reducers = combineReducers({
  admin: adminReducer,
  borrower: borrowerReducer,
  lender: lenderReducer,
  subscription: subscriptionReducer,
  cms: cmsReducer,
  moreOption: moreOptionReducer,
  homePage: homePageReducer,
  helpAndSupport: helpAndSupportReducer,
  loan: loanReducer,
  webManager: webManagerReducer,
  notification: notificationReducer

});


export default reducers;
